<template>
    <b-card>
        <h4>
            فیلتر نمودار کارمزد ها
        </h4>
        <b-button-group class="my-50 mr-75 " size="sm" v-if="false">
            <b-button
                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                    class="btn-icon"
                    variant="outline-warning"
                    @click="perv"
            >
                <feather-icon icon="ChevronRightIcon"/>
            </b-button>
            <b-button
                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                    :variant="duration === 'MONTHLY'?'warning':'outline-warning'"
                    @click="duration = 'MONTHLY'"
            >
                ماهانه
            </b-button>
            <b-button
                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                    :variant="duration === 'YEARLY'?'warning':'outline-warning'"
                    @click="duration = 'YEARLY'"
            >
                سالانه
            </b-button>
            <b-button
                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                    class="btn-icon"
                    variant="outline-warning"
                    @click="next"
            >
                <feather-icon icon="ChevronLeftIcon"/>
            </b-button>
        </b-button-group>

        <b-dropdown
                text="Action"
                variant="outline-warning"
                class="my-50 mr-75"
                size="sm"
        >
            <template #button-content>
                {{$coins[relatedCoin].persianName}}
            </template>
            <b-dropdown-item v-for="coin in coins" :key="coin.coin" @click="$emit('update:relatedCoin', coin.coin)">
                {{$coins[coin.coin].persianName}}
            </b-dropdown-item>
        </b-dropdown>
        <!-- group 2 -->
        <div class="d-flex justify-content-between">
            <p>مجموع {{duration==='MONTHLY'? 'ماه' : (duration==='YEARLY' ? 'سال' : 'روز')}}</p>
            <p>{{$toLocal(sum,$decimal[relatedCoin]) || 0}} {{$coins[relatedCoin].persianName}}</p>
        </div>
        <div class="d-flex justify-content-between">
            <p>{{duration==='MONTHLY'? 'در ماه' : duration==='YEARLY' ? 'در سال' : 'در روز'}}</p>
            <p>{{$jmoment(dateParam.from).format(duration==='MONTHLY'? 'jMMMM jYYYY' : (duration==='YEARLY' ? 'jYYYY' : 'jMMMM jYYYY jDDD'))}}</p>
        </div>

    </b-card>
</template>

<script>
    const step = {
        'YEARLY': 'jYear',
        'MONTHLY': 'jMonth',
        'DAILY': 'day',
    }

    import {
        BCard,
        BButton,
        BButtonGroup,
        BDropdown,
        BDropdownItem,
    } from 'bootstrap-vue'
    // import {$themeColors} from '@themeConfig'
    export default {
        name: "feeFilterChart",
        components: {
            BCard,
            BButton,
            BButtonGroup,
            BDropdownItem,
            BDropdown,
        },
        props: ['sum', 'dateParam', 'coins', 'relatedCoin'],
        data() {
            return {
                duration: 'MONTHLY',
                number: 0,
                date:'',
            }
        },
        watch:{
            'duration'(){
                this.number = 0
                this.getDate()
            },
            'relatedCoin'(){
                this.number = 0
                this.getDate()
            }
        },
        methods: {
            setDate(date, num) {
                return num < 0 ? date.subtract(Math.abs(num), step[this.duration]) : date.add(num, step[this.duration])
            },
            firstDate(date) {
                return date.startOf(step[this.duration]).format(this.$dateFormat['en'])
            },
            lastDate(date) {
                return date.endOf(step[this.duration]).format(this.$dateFormat['en'])
            },

            setDuration(date) {
                this.dateParam.from = this.firstDate(date)
                this.dateParam.to = this.lastDate(date)
                if (this.duration === 'DAILY') {
                    delete this.dateParam.to
                }
            },
            perv() {
                this.loading = true
                this.number--
                this.getDate()
            },
            next() {
                this.loading = true
                this.number++
                this.getDate()
            },
            async getDate() {
                let date = this.$jmoment()
                date = this.setDate(date, this.number)
                this.setDuration(date)
                this.$emit('change')
            }
        }
    }
</script>

<style scoped>

</style>
